import {
    PART_DESCRIPTION_ATTRIBUTE_NAME,
    U_JOINT_ATTRIBUTE_NAME,
    U_JOINT_ATTRIBUTE_VALUE,
} from "../../../common/specification-search/constants";
import {
    FILTERS_LEVELS,
    getRelatedValidGroups,
    getUniqueValuesFromArrays,
    PART_TYPES_LEVELS,
} from "../../../driv-parts-result-filters/clientlibs/src/helpers";
import {
    FILTERS_QS_OPTIONS,
    QUALIFIERS_FILTERS_PROPERTY,
    SPECIFICATION_PROPERTIES,
    UOM_VALUES,
} from "./constants";
import { createUrlToGoToSearchResults } from "../../../common/partFinderCorporate.helpers";
import hash from "object-hash";
import { PART_FINDER_CORPORATE_SEARCH_TYPES } from "../../../common/partFinderCorporate.constants";

export const getCorporateWtbUrl = ({ partType = "any", brand, wtbPath, locType = "all" }) => {
    const urlOrigin = window.location.origin;

    return `${urlOrigin}${wtbPath}.html#dealerType=physical&locType=${locType}&country=US&partType=${partType}&subBrand=all&brand=${brand}`;
};
const DEFAULT_QS_PARAMS = { ignoreQueryPrefix: true };
export const getQsParams = (options = {}) =>
    Qs.parse(window.location.search, { ...DEFAULT_QS_PARAMS, ...options });

export const getHeadersWithSignedRequest = (signedRequest) => {
    return signedRequest
        ? {
              headers: {
                  "Content-Type": "application/json",
                  signed_request: signedRequest,
              },
          }
        : {};
};

export const getHashParams = (options = {}) => Qs.parse(window.location.hash.slice(1), options);
export const getDoubleEncodedHashParams = (options = {}) => {
    return Qs.parse(decodeURIComponent(window.location.hash.slice(1)), options);
};

const getValuesOrNull = (values) => {
    if (typeof values === "string" || typeof values === "number") {
        return values;
    }

    if (Array.isArray(values)) {
        if (!values.length) return null;

        return values;
    }

    if (Object.values(values).length) return replaceEmptyWithNull(values);

    return null;
};

export const replaceEmptyWithNull = (filters) => {
    return Object.entries(filters).reduce(
        (acc, [key, values]) => ({
            ...acc,
            [key]: getValuesOrNull(values),
        }),
        {},
    );
};

const getValuesForFilters = (values) => {
    if (typeof values === "string") return values;

    return (values || []).filter(Boolean).join(",");
};

const CATEGORIES_PROPERTIES_MAP = {
    [FILTERS_LEVELS.CATEGORIES]: {
        propertyName: "category_ids",
        nestedPropertyName: "sub_category_ids",
    },
    [FILTERS_LEVELS.BRANDS]: {
        propertyName: "brand_codes",
        nestedPropertyName: "sub_brand_codes",
    },
};

export const prepareFilters = ({
    filters: { configs = {}, categories = {} } = {},
    validGroups = [],
}) => {
    const configsValidGroups =
        validGroups.length && Object.keys(configs).length
            ? getRelatedValidGroups({ checkedValues: configs, validGroups })
            : {};

    let preparedConfigs = {};
    const validGroupsArray = Object.entries(configsValidGroups);

    if (validGroupsArray?.length) {
        preparedConfigs = validGroupsArray.reduce((acc, [key, values]) => {
            const stringifiedValues = getValuesForFilters(getUniqueValuesFromArrays(values));
            if (!stringifiedValues) return acc;

            return {
                ...acc,
                [`${key}s`]: stringifiedValues,
            };
        }, {});
    } else {
        preparedConfigs = Object.entries(configs).reduce((acc, [key, values]) => {
            if (key.startsWith(`${QUALIFIERS_FILTERS_PROPERTY}_`)) {
                values = preEncodeFiltersString(values);
                return {
                    ...acc,
                    [QUALIFIERS_FILTERS_PROPERTY]: Array.isArray(values) ? values : [values],
                };
            }
            const stringifiedValues = getValuesForFilters(values);
            if (!stringifiedValues) return acc;

            return {
                ...acc,
                [`${key}_ids`]: stringifiedValues,
            };
        }, {});
    }

    const preparedCategories = Object.entries(categories).reduce(
        (categoriesAcc, [categoriesKey, categoriesValues]) => {
            if (categoriesKey === PART_TYPES_LEVELS.PART_TYPES) {
                return {
                    ...categoriesAcc,
                    part_types_and_positions_json: JSON.stringify(
                        Object.entries(categoriesValues).map(([partType, positions]) => ({
                            part_type_id: partType,
                            ...(positions
                                ? {
                                      position_ids: Array.isArray(positions)
                                          ? positions
                                          : [positions],
                                  }
                                : {}),
                        })),
                    ).replace(/"/g, ""),
                };
            }

            const { propertyName, nestedPropertyName } = CATEGORIES_PROPERTIES_MAP[categoriesKey];

            const stringifiedCategoriesValues = Object.keys(categoriesValues);
            const stringifiedSubCategoriesValues = getValuesForFilters(
                Object.values(categoriesValues).map(getValuesForFilters),
            );

            return {
                ...categoriesAcc,
                ...(stringifiedCategoriesValues
                    ? {
                          [propertyName]: stringifiedCategoriesValues,
                      }
                    : {}),
                ...(stringifiedSubCategoriesValues
                    ? {
                          [nestedPropertyName]: stringifiedSubCategoriesValues,
                      }
                    : {}),
            };
        },
        {},
    );

    return Object.entries({
        ...preparedConfigs,
        ...preparedCategories,
    })
        .map(([key, value]) => {
            if (key === QUALIFIERS_FILTERS_PROPERTY) {
                return Qs.stringify({ [key]: value }, { arrayFormat: "brackets", encode: false });
            }
            return `${key}=${value}`;
        })
        .join("&");
};

export const getFiltersToUpdate = () => {
    const { filters = {} } = getHashParams() || {};

    if (Object.values(filters?.configs || {})?.length) {
        return {
            none: true,
            categories: false,
            parts: false,
            configs: false,
        };
    }

    const categories = filters?.categories;

    if (categories) {
        switch (true) {
            case !!categories[PART_TYPES_LEVELS.PART_TYPES] ||
                !!categories[PART_TYPES_LEVELS.POSITIONS]:
                return {
                    configs: true,
                };
            case !!categories[FILTERS_LEVELS.CATEGORIES] ||
                !!categories[FILTERS_LEVELS.SUB_CATEGORIES]:
                return {
                    configs: true,
                    parts: true,
                };
            case !!categories[FILTERS_LEVELS.BRANDS] || !!categories[FILTERS_LEVELS.SUB_BRANDS]:
                return {
                    configs: true,
                    parts: true,
                    categories: true,
                };
        }
    }

    return {};
};

export const getFiltersForRequest = (filters) => {
    if (!window.location.hash) return;
    const parsedFilters = getHashParams(FILTERS_QS_OPTIONS);
    if (!parsedFilters) return;
    return prepareFilters({
        ...parsedFilters,
        validGroups: filters.configs?.valid_groups,
    });
};

export const getSelectedFiltersFromData = (data) => {
    const selectedFilters = {};
    const { brands, categories, partTypes } = data || {};

    if (brands) {
        selectedFilters.categories = {
            ...selectedFilters.categories,
            brands: brands.reduce((acc, brand) => {
                return { ...acc, [brand.code]: brand.sub_brands.map((subBrand) => subBrand.code) };
            }, {}),
        };
    }

    if (categories) {
        selectedFilters.categories = {
            ...selectedFilters.categories,
            categories: categories.reduce((acc, category) => {
                return {
                    ...acc,
                    [category.id]: category.sub_categories.map(
                        (subCategory) => `${subCategory.id}`,
                    ),
                };
            }, {}),
        };
    }

    if (partTypes) {
        selectedFilters.categories = {
            ...selectedFilters.categories,
            part_type: partTypes.reduce((acc, group) => {
                return { ...acc, [group.part_type.id]: group.position.map((item) => `${item.id}`) };
            }, {}),
        };
    }

    return selectedFilters;
};

export const mapQualifiersToConfigs = (qualifiers) => {
    return qualifiers.map((item) => {
        return {
            name: item.qualifier_name,
            type: `${QUALIFIERS_FILTERS_PROPERTY}_${item.qualifier_name}`,
            values: item.qualifier_values.map((value) => ({
                id: value,
                value,
            })),
        };
    });
};

export const preEncodeFiltersString = (values) => {
    if (typeof values === "string") {
        return encodeURIComponent(values);
    } else return Object.keys(values).map((key) => encodeURIComponent(values[key]));
};

export const getUJointFilterAttribute = (filters) => {
    return filters.find((attr) => attr.name === U_JOINT_ATTRIBUTE_NAME);
};

export const isUJointFilters = (filters) => {
    return !!getUJointFilterAttribute(filters[SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS] || []);
};

export const isUJointFiltersValue = (filters) => {
    return !!(filters[SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS] || []).find(
        (attr) => attr.value === U_JOINT_ATTRIBUTE_VALUE,
    );
};

export const getUpdatedSpecificationsList = ({ newListData, filters, currentListData }) => {
    if (!filters || isUJointFilters(filters)) return newListData;
    if (!currentListData && !!newListData) return newListData;
    if (!newListData && !!currentListData) return currentListData;

    const attributeFilters = (filters[SPECIFICATION_PROPERTIES.ATTRIBUTE_FILTERS] || []).filter(
        (filter) => filter.name !== PART_DESCRIPTION_ATTRIBUTE_NAME,
    );
    if (!attributeFilters?.length) return newListData;

    const newMasterData = newListData[SPECIFICATION_PROPERTIES.MASTER_LIST_PROPERTY] || [];
    const currentMasterData = currentListData[SPECIFICATION_PROPERTIES.MASTER_LIST_PROPERTY] || [];

    return {
        ...newListData,
        [SPECIFICATION_PROPERTIES.MASTER_LIST_PROPERTY]: currentMasterData.map((item) => {
            if (
                attributeFilters.some(
                    (filter) => filter.name === item.name && filter.uom === item.uom,
                )
            ) {
                return item;
            }

            const newMasterDataItem = newMasterData.find(
                (newItem) => newItem.name === item.name && newItem.uom === item.uom,
            );

            return newMasterDataItem || item;
        }),
    };
};

const DIAMETERS_ATTRIBUTES = {
    AXIS_1: "Bearing Cap Axis 1 Diameter",
    AXIS_2: "Bearing Cap Axis 2 Diameter",
};

const DIMENSIONS = {
    [UOM_VALUES.IN]: '"',
    [UOM_VALUES.MM]: "mm",
};

const getDiameterLabel = (value, dimension) => {
    if (value) return `${value}${dimension}`;
    return "-";
};

export const getFilterValues = (value) => {
    const { part_attributes } = value;

    return part_attributes.reduce((result, data) => {
        if (data) {
            const filteredValue = {
                name: data.attribute,
                uom: data.attribute_uom,
                value: data.attribute_value,
            };

            return [...result, filteredValue];
        }

        return result;
    }, []);
};

export const getSelectedDiameterValue = (attributeFilters) => {
    const [axis1Attr = {}, axis2Attr = {}] = attributeFilters.filter(
        (attr) => attr.name !== U_JOINT_ATTRIBUTE_NAME,
    );
    const dimension = DIMENSIONS[axis1Attr.uom];

    return `${getDiameterLabel(axis1Attr.value, dimension)}/${getDiameterLabel(
        axis2Attr.value,
        dimension,
    )}`;
};

export const openDiagramPage = (product) => {
    const qsParams = getQsParams();
    const hashString = getHashParams(FILTERS_QS_OPTIONS);
    const pagePath = window.location.pathname.replace(".html", "");
    const queryObj = {
        ...qsParams,
        isInteractiveDiagram: true,
        brandInfo: { label: product.wtb_brand_name, value: product.brand },
    };
    const url = createUrlToGoToSearchResults({
        queryObj,
        path: pagePath,
        hashString,
    });

    Vue.Global.Analytics.trackEvent(
        "interactive-diagrams-results",
        "go-to-interactive-diagrams-page",
        product.part_number,
        null,
        {
            callback: function () {
                return window.location.assign(url);
            },
        },
    );
};

export const generateCustomId = (value) => hash(value);

export const mapPartToSalesforceObj = ({ part, originalPart = null, shouldAddId = true }) => {
    const {
        make,
        model,
        year,
        part: partNumber,
        searchType,
        heavyMfr,
        heavyBase,
        performanceMfr,
        performanceBase,
        VIN_number,
    } = getQsParams();

    const isPerformance = searchType === PART_FINDER_CORPORATE_SEARCH_TYPES.ENGINE_PERFORMANCE;

    const mfrValue = isPerformance ? performanceMfr?.label : heavyMfr?.label;
    const baseValue = isPerformance ? performanceBase?.label : heavyBase?.label;

    const checkIfExist = (attr, isId = false) => {
        const noValue = isId ? "-1" : "";
        return attr ? attr.toString() : noValue;
    };

    const getPartNumberForMissingCoverage = () =>
        partNumber ? decodeURIComponent(partNumber) : null;

    const _part = originalPart || part;

    const id = part && shouldAddId ? { id: generateCustomId(_part) } : {};

    return {
        ...id,
        body_num_doors: checkIfExist(part?.body_num_doors), //todo
        body_type: checkIfExist(part?.vcdb_attributes?.["Body Type"]),
        brand_code: checkIfExist(part?.brand),
        brand_name: checkIfExist(part?.brand_name),
        category_id: checkIfExist(part?.category?.category_id, true),
        category_value: checkIfExist(part?.category?.category_value),
        drive_type: checkIfExist(part?.drive?.value),
        engine_base_id: checkIfExist(part?.engine_base?.id, true),
        engine_base_value: checkIfExist(part ? part?.engine_base_value : baseValue),
        engine_designation: checkIfExist(part?.engine_designation?.value),
        engine_vin_id: checkIfExist(part?.engine_vin?.id, true),
        engine_vin_value: checkIfExist(part?.engine_vin?.value),
        fuel_type: checkIfExist(part?.vcdb_attributes?.["Fuel Type"]),
        make_id: checkIfExist(part ? part.make?.id : make?.value, true),
        make_value: checkIfExist(part ? part?.make?.value : make?.label),
        manufacturer: checkIfExist(part ? part?.mfr?.value : mfrValue),
        model_id: checkIfExist(part ? part.model?.id : model?.value, true),
        model_value: checkIfExist(part ? part?.model?.value : model?.label),
        part_note: checkIfExist(part?.part_note), //todo
        part_number: checkIfExist(part ? part?.part_number : getPartNumberForMissingCoverage()),
        part_type_id: checkIfExist(part?.category?.part_type_id, true),
        part_type_value: checkIfExist(part?.category?.part_type_value),
        position_id: checkIfExist(part?.position?.id, true),
        position_value: checkIfExist(part?.position?.value),
        position: checkIfExist(part?.position?.value),
        qualifier: checkIfExist(part?.additional_fit_criteria),
        resulting_part_number: part?.resulting_part_number || (part ? part.part_number : "NA"),
        resulting_brand_code: checkIfExist(part?.resulting_brand_code),
        resulting_brand_name: checkIfExist(part?.resulting_brand_name),
        sub_category_id: checkIfExist(part?.category?.sub_category_id, true),
        sub_category_value: checkIfExist(part?.category?.sub_category_value),
        sub_model: checkIfExist(part?.sub_model?.value),
        transmission_control_type: checkIfExist(
            part?.vcdb_attributes?.["Transmission Control Type"],
        ),
        transmission_MFG_code: checkIfExist(part?.transmission_MFG_code), //todo
        VIN_number: checkIfExist(VIN_number?.value), //todo
        wheel_base: checkIfExist(part?.wheel_base), //todo
        year: checkIfExist(year?.value),
    };
};

export const configurePartObject = ({ part, isInterchanges }) =>
    isInterchanges
        ? {
              ...part,
              resulting_part_number: part?.own_part_number,
              resulting_brand_code: part?.own_brand_code,
              resulting_brand_name: part?.own_brand_name,
              category: {
                  part_type_value: part?.part_name,
              },
              mfr: {
                  value: part?.brand_name,
              },
          }
        : {
              ...part,
              category: {
                  part_type_value: part?.part_name,
              },
          };
